import React, {useState} from "react";
import {navigate} from "gatsby";
import {getConnected} from "../../util/helper";
const SecuredRoute = ({ component: Component, location, ...rest }: any) => {
    const [connected, _] = useState<any>(getConnected());
    if (typeof window !== "undefined")
    if ((!connected || !connected.id) && location.pathname !== `/app/login`) {
            navigate(`/app/login?returnUrl=${location.pathname}${location.search}`);
        return null
    }
    return <Component location={location} {...rest} />;
}
export default SecuredRoute;