import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { post } from '../../services/BaseService';
import { quotationUrls } from '../../util/api_constant';
import { euro, fcfca } from '../../util/constant';
import { NumberFormat, OpenMessage, showMontantText, Spinner } from '../../util/helper';
import { ErrorMessage, Quotation } from '../../util/type';
import { smulatorSchema } from '../../util/yuSchema';

const maritimeMessage = "Veuillez vous rapprocher du service client par whatsapp ou mail pour un accompagnement sur mesure.";

export default function SimulatorForm() {
    const { register, handleSubmit, formState: { errors } , formState, watch } = useForm<any>({
        mode: 'onTouched',
        resolver: yupResolver(smulatorSchema)
      });
      const values = watch();
      /*errors,*/
      const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
      const [errorRes, setErrorRes] = useState<ErrorMessage>();
      const [quotation, setQuotation] = useState<Quotation>();

      const onSubmit = async (data: Quotation) => {

        setIsSubmitted(true)
        post(quotationUrls.base, data).then((res: Quotation) => {
          setQuotation(res)
          setErrorRes(undefined);
          setIsSubmitted(false)
        }).catch((e: any) => {
          setErrorRes(e.response.data);
             console.log("err" ,e)
             setQuotation(undefined)
             setIsSubmitted(false)
        });

      };
  return <div className="card card-lg">
     <div className="card-body">
       <h4>Remplissez le formulaire</h4>
       {/* Form */}
       <form onSubmit={handleSubmit(onSubmit)}>
         {errorRes && OpenMessage(errorRes) }

         {/* Form */}
         <div className="row">

           <div className="col-sm-6 mb-3 mb-sm-0">
             <label
               className="form-label visually-hidden"
               htmlFor="length"
             >
               Longueur /cm
             </label>
             <input
               type="text"
               className="form-control form-control-lg"
               id="length"
               placeholder="Longueur /cm"
               aria-label="Longueur /cm"
               {...register("length")}
               disabled={isSubmitted}
             />

             {errors.length && <span className="help-block with-errors" style={{ "color": "#e20c0c" }}>{errors.length.message}</span>}

           </div>

           {/* End Form */}
           {/* Form */}
           <div className="col-sm-6 mb-3 mb-sm-0">
             <label
               className="form-label visually-hidden"
               htmlFor="hireUsFormCompanyName"
             >
               Largeur / cm
               <span className="form-label-secondary">(Optional)</span>
             </label>
             <input
               type="text"
               className="form-control form-control-lg"
               id="width"
               {...register("width")}
               placeholder="Largeur / cm"
               aria-label="Largeur / cm"
               disabled={isSubmitted}
             />

             {errors.width && <span className="help-block with-errors" style={{ "color": "#e20c0c" }}>{errors.width.message}</span>}
           </div>
         </div>
         {/* End Form */}
         {/* Form */}
         <div className="row">
           <div className="col-sm-6 mb-3 mb-sm-0">
             <label
               className="form-label visually-hidden"
               htmlFor="hireUsFormCompanyName"
             >
               Hauteur / cm
               <span className="form-label-secondary">(Optional)</span>
             </label>
             <input
               type="text"
               className="form-control form-control-lg"
               // name="height"
               id="height"
               {...register("height")}
               placeholder="Hauteur / cm"
               aria-label="Hauteur / cm"
               disabled={isSubmitted}
             />

             {errors.height && <span className="help-block with-errors" style={{ "color": "#e20c0c" }}>{errors.height.message}</span>}

           </div>
           <div className="col-sm-6 mb-3 mb-sm-0">
             <label
               className="form-label visually-hidden"
               htmlFor="hireUsFormCompanyName"
             >
               Poids en kg
               <span className="form-label-secondary">(Optional)</span>
             </label>
             <input
               type="text"
               className="form-control form-control-lg"
               //name="weight"
               {...register("weight")}
               id="weight"
               placeholder="Poids en kg"
               aria-label="Poids en kg"
               disabled={isSubmitted}
             />

             {errors.weight && <span className="help-block with-errors" style={{ "color": "#e20c0c" }}>{errors.weight.message}</span>}

           </div>
         </div>
         {/* End Form */}
         {/* Form */}
         <div className="mb-3">
           <label
             className="form-label visually-hidden"
             htmlFor="products"
           >
             Nombres de colis
             <span className="form-label-secondary">(Optional)</span>
           </label>
           <input
             type="text"
             className="form-control form-control-lg"
             id="products"
             {...register("products")}
             placeholder="Nombres de colis"
             aria-label="Nombres de colis"
             disabled={isSubmitted}
           />
           {errors.products && <span className="help-block with-errors" style={{ "color": "#e20c0c" }}>{errors.products.message}</span>}


         </div>
         {/* End Form */}
         {/* Select */}
         <div className="mb-3">
           <label
             className="form-label visually-hidden"
             htmlFor="offer"
           >
             Offres
           </label>
           <select
             {...register("offer")}
             id="offer"
             className="form-select form-select-lg"
             aria-label="Pays de départ"
             disabled={isSubmitted}
           >
             <option value={""}>Offres</option>
             <option value={"ZEN"}>Zen</option>
             <option value={"FLEX"}>Flex</option>
             <option value={"CHILL"}>Chill</option>
             <option value={"LUXE"}>Luxe</option>
             <option value={"FRET"}>Fret Maritime</option>

           </select>
           {errors.offer && <span className="help-block with-errors" style={{ "color": "#e20c0c" }}>{errors.offer.message}</span>}
           {values?.offer === "FRET" && <span className="help-block with-errors" style={{ "color": "#FFA500" }}>{maritimeMessage}</span>}

         </div>
         {/* End Select */}
         {/* Select */}
         <div className="mb-3">
           <label
             className="form-label visually-hidden"
             htmlFor="type"
           >
             Type
           </label>
           <select
             {...register("type")}
             id="type"
             className="form-select form-select-lg"
             aria-label="Pays de départ"
             disabled={isSubmitted}
           >
             <option value={""}>Type</option>
             <option value={"AERIAL"}>Fret Aérien</option>
             <option value={"MARITIME"}>Fret Maritime</option>

           </select>
           {errors.type && <span className="help-block with-errors" style={{ "color": "#e20c0c" }}>{errors.type.message}</span>}
           {values?.type === "MARITIME" && <span className="help-block with-errors" style={{ "color": "#FFA500" }}>{maritimeMessage}</span>}
         </div>
         {/* End Select */}
         {/* Select */}
         <div className="mb-3">
           <label
             className="form-label visually-hidden"
             htmlFor="category"
           >
             Catégorie
           </label>
           <select
             {...register("category")}
             id="category"
             className="form-select form-select-lg"
             aria-label="Pays de départ"
             disabled={isSubmitted}
           >
             <option value={""}>Catégorie</option>
             <option value={"PART"}>Particulier</option>
             <option value={"PRO"}>Pro</option>

           </select>
           {errors.category && <span className="help-block with-errors" style={{ "color": "#e20c0c" }}>{errors.category.message}</span>}

         </div>
         {/* End Select */}
         {/* Select */}
         <div className="row">
           <div className="col-sm-6 mb-3 mb-sm-3">
             <label
               className="form-label visually-hidden"
               htmlFor="departureCode"
             >
               Pays de départ
             </label>
             <select
               // name="departureCode"
               {...register("departureCode")}
               id="departureCode"
               className="form-select form-select-lg"
               aria-label="Pays de départ"
               disabled={isSubmitted}
             >
               <option value={""}>Pays de départ</option>
               <option value={"FRANCE"}>FRANCE</option>
               <option value={"UNITED-STATES"}>États-Unis</option>
             </select>
           {errors.departureCode && <span className="help-block with-errors" style={{ "color": "#e20c0c" }}>{errors.departureCode.message}</span>}

           </div>
           {/* End Select */}
           {/* Select */}
           <div className="col-sm-6 mb-3 mb-sm-3">
             <label
               className="form-label visually-hidden"
               htmlFor="destinationCode"
             >
               Pays de livraison
             </label>
             <select
               // name="destinationCode"
               {...register("destinationCode")}
               id="destinationCode"
               className="form-select form-select-lg"
               aria-label="Pays de livraison"
               disabled={isSubmitted}
             >
               <option value={""}>Pays de livraison</option>
               <option value={"MALI"}>Mali</option>
               <option value={"SENEGAL"}>Sénégal</option>
               <option value={"IVORY-COST"}>Côte d'Ivoire</option>
               <option value={"BENIN"}>Benin</option>
               <option value={"TOGO"}>Togo</option>
               <option value={"GABON"}>Gabon</option>
               <option value={"CONGO-BRAZAVILLE"}>Congo</option>
               {/* <option value={"BURKINA-FASO"}>BURKINA-FASO</option> */}

             </select>
           {errors.destinationCode && <span className="help-block with-errors" style={{ "color": "#e20c0c" }}>{errors.destinationCode.message}</span>}

           </div>
         </div>
         {/* End Select */}
         {/* Select */}

         <div className="mb-3 ">
           <label
             className="form-label visually-hidden"
             htmlFor="currency"
           >
             Monnaie
           </label>
           <select
             // name="departureCode"
             {...register("currency")}
             id="currency"
             className="form-select form-select-lg"
             aria-label="Monnaie"
             disabled={isSubmitted}
           >
             <option value={""}>Monnaie</option>
             <option value={fcfca}>FCFA</option>
             <option value={euro}>Euro</option>
           </select>
           {errors.currency && <span className="help-block with-errors" style={{ "color": "#e20c0c" }}>{errors.currency.message}</span>}

         </div>
         {/* End Select */}
         {/* Select */}


         <div className="d-grid mb-2">
           <button type="submit" className="btn btn-primary btn-lg" disabled={!formState.isValid || isSubmitted || values?.type === "MARITIME" || values?.offer === "FRET"}
           //  onClick={handleSubmit(onSubmit)}
           >
            {isSubmitted ?  Spinner() : "Calculer"}

           </button>
         </div>

         {
           quotation && <div className="text-center">
             <span className="d-block small">{showMontantText(quotation.currency)}</span>
             <span className="display-8 text-primary">{NumberFormat(quotation.price, quotation.currency)}</span>
           </div>

         }

       </form>
       {/* End Form */}
     </div>
   </div>
   {/* End Card */}

}
