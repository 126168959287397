import React, {useEffect, useState} from "react";
import AuthService from "../../services/AuthService";
import {AUTH_TOKEN_NAME, AUTH_TOKEN_PREFIX, AUTH_TOKEN_SUFFIX, CONNECTED} from "../../services/FetchInterceptor";
import {Helmet} from "react-helmet";



const initialesValues = { username: "", password: "" };

const Login = ({}: any) => {
    const [eye, setEye] = useState(false);
    const [formValues, setFormValues] = useState(initialesValues);
    const [formErrors, setFormErrors] = useState<any>({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorSubmitted,  setErrorSubmitted] = useState("");
    const showPwd = () =>  setEye(!eye);
    
    const submit = () => {
        setLoading(true);
        const {username, password} = formValues;
        AuthService.login(username, password).then(
            (response) => {
                let token = response.headers['x-auth-token'];
                if (token){
                    if (localStorage) {
                        // const {id, fullName, username, phoneNumber, countryCode, roles} = response.data;
                        localStorage.setItem(AUTH_TOKEN_NAME, AUTH_TOKEN_PREFIX + token + AUTH_TOKEN_SUFFIX);
                        localStorage.setItem(CONNECTED, JSON.stringify(response.data));
                    }
                    const pathName = location.href;
                    if (pathName.includes('returnUrl')){
                        const split = pathName.split('returnUrl=');
                        location.replace(`${split[1]}`);
                    }else
                        location.replace('/app/profile');
                }
            }
        ).catch((error:any) => {
            error.response ?
                error.response.status === 400 ? setErrorSubmitted("Nom d'utilisateur ou mot de passe incorrect") :
                setErrorSubmitted(error.response.data.message) :
                setErrorSubmitted("Erreur de connexion au serveur !");
        }).finally(()=> setLoading(false))
    };

    //input change handler
    const handleChange = (e:any) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    //form submission handler
    const handleSubmit = (e:any) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    //form validation handler
    const validate = (values:any) => {
        let errors:any = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.username) {
            errors.username = "Ne peut être vide";
        } else if (!regex.test(values.username)) {
            errors.username = "Email invalide !";
        }

        if (!values.password) {
            errors.password = "Ne peut être vide";
        } else if (values.password.length < 4) {
            errors.password = "Le mot de passe doit comporter plus de 4 caractères";
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submit();
        }
    }, [formErrors]);
    return (
        <>
            <Helmet>
                <title>Afrisends | Connexion à Afrisends</title>
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap" rel="stylesheet" />
                <link rel="stylesheet" href="/assets/css/vendor.min.css" />
                <link rel="stylesheet" href="/assets/css/theme.min.css?v=1.0" />
                <link rel="stylesheet" href="/assets/css/login.css" />
            </Helmet>
            <main id="content" role="main">
                <div className="d-lg-flex half">
                    <div
                        className="bg order-1 order-md-2" style={{ background: "linear-gradient(#ff64001c 25%, #ff6400b5 90%), linear-gradient(to right, #ff64001c 0%, #ef88453d 90%), url(/assets/img/950x950/img5.jpg)", backgroundSize:'cover' }}
                    ></div>
                    <div className="contents order-2 order-md-1">
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-7 login-content">
                                    <h3>Connexion à Afrisends</h3>
                                    {errorSubmitted !== "" && (<span className="error-submit-msg">{errorSubmitted}</span>)}
                                    <form onSubmit={handleSubmit} noValidate>
                                        <div className="form-group mb-3">
                                            {/*<label htmlFor="username">Adresse email</label>*/}
                                            <input
                                                name="username"
                                                type="text"
                                                className="form-control"
                                                placeholder="your-email@gmail.com"
                                                id="username"
                                                value={formValues.username}
                                                onChange={handleChange}
                                            />
                                            {formErrors.username && (<div className="error-msg">{formErrors.username}</div>)}
                                        </div>
                                        <div className="form-group mb-3">
                                            {/*<label htmlFor="password">Mot de passe</label>*/}
                                            <div className="input-group-merge">
                                                <input
                                                    name="password"
                                                    type={eye ? "text" : "password"}
                                                    className="form-control"
                                                    placeholder="Mot de passe"
                                                    id="password"
                                                    value={formValues.password}
                                                    onChange={handleChange}
                                                />
                                                <span id="changePassTarget" className="input-group-append input-group-text"
                                                      onClick={showPwd}><i id="changePassIcon" className={eye ? "bi-eye-slash" : "bi-eye"}/>
                                        </span>
                                            </div>
                                            {formErrors.password && (<div className="error-msg">{formErrors.password}</div>)}
                                        </div>
                                        <div className="d-flex mb-5 align-items-center">
                                   <span>
                                        <input className="form-check-input" type="checkbox" value="" id="termsCheckbox" required/>
                                        <label className="form-check-label" htmlFor="termsCheckbox" style={{marginLeft:8}}>Se souvenir</label>
                                   </span>
                                            <span className="ml-auto">
                                        <a href="/app/forgotPassword" className="forgot-pass">Mot de passe oublié ?</a>
                                    </span>
                                        </div>
                                        <button type="submit" className="btn btn-block btn-primary" disabled={loading}>
                                            {loading ? "En cours ..." : "S'identifier"}
                                        </button>
                                    </form>
                                    <br/>
                                    <span className="ml-auto text-muted">
                                Pas encore de compte ? <a className="link" href="/app/register">Inscrivez-vous ici</a>
                            </span>
                                    <br/><br/>
                                    <span className="ml-auto text-muted">
                                <a className="link" href="https://www.afrisends.com">Retourné à l'accueil</a>
                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>

    )
}
export default Login;
