import React from "react";
import {navigate} from "gatsby";
import {getConnected} from "../../util/helper";
const Redirect = (props: any) => {
    let connected = getConnected();
    // const [auth, _] = useState<boolean>(false); //todo - handle auth state from store
    if (typeof window !== "undefined") {
        if ((!connected || !connected.id) && location.pathname !== `/app/login`) {
            navigate("/app/login");
        } else {
            navigate("/");
        }
    }
    return null
}
export default Redirect;