import React from "react";
import {Router} from "@reach/router";
import Simulator from "./app/simulator";
import SecuredRoute from "../components/auth/SecuredRoute";
import Login from "./app/login";
import Register from "./app/register";
import Redirect from "../components/auth/Redirect";
import Profile from "./app/profile";
import Dashboard from "./app/dashboard";
import Careers from "./careers";
import ForgotPassword from "./app/forgotPassword";
import "/static/assets/css/vendor.min.css";
import "/static/assets/css/theme.min.css?v=1.0";
import PlaceOrder from "./app/place-order";

const App = () => {

    return <>
        <Router basepath="/app" >
            {/*<SecuredRoute path="/simulator" component={Simulator}/>*/}
            <SecuredRoute path="/profile" component={Profile}/>
            <SecuredRoute path="/dashboard" component={Dashboard}/>
            <SecuredRoute path="/careers" component={Careers}/>
            <SecuredRoute path="/place-order" component={PlaceOrder}/>
            <Login path="/login"/>
            <Simulator path="/simulator"/>
            <Register path="/register"/>
            <ForgotPassword path="/forgotPassword"/>
            <Redirect path="/"/>
        </Router>
    </>
}
export default App;
